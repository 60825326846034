import React, { useEffect, useRef } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import {
  SideNavigation,
  SideNavigationDomRef,
  Ui5DomRef,
  Ui5CustomEvent,
} from '@ui5/webcomponents-react';
import UI5Element from '@ui5/webcomponents-base/dist/UI5Element.js';
import { SideNavigationSelectionChangeEventDetail } from '@ui5/webcomponents-fiori/dist/SideNavigation';

const { getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;
export interface SideNavigationObserverProps {
  className: string;
  style: any;
  collapsed: boolean;
  selectedId: string;
  noIcons: boolean;
  onSelectionChange: (
    event: Ui5CustomEvent<SideNavigationDomRef, SideNavigationSelectionChangeEventDetail>,
  ) => void;
}
type Props = React.PropsWithChildren<SideNavigationObserverProps>;

let EventMap = {};

let TreeNodeTag = 'ui5-tree';
let ListNodeTag = 'ui5-list';
let LiTreeTag = 'ui5-li-tree';
let IconTag = 'ui5-icon';
if (process.env.NODE_ENV !== 'test') {
  TreeNodeTag = `ui5-tree-${process.env.APP_NAME}`;
  ListNodeTag = `ui5-list-${process.env.APP_NAME}`;
  LiTreeTag = `ui5-li-tree-${process.env.APP_NAME}`;
  IconTag = `ui5-icon-${process.env.APP_NAME}`;
}

const getTreeRoot = (currentDom: Ui5DomRef) =>
  currentDom?.shadowRoot?.querySelector(`.ui5-sn-root ${TreeNodeTag}`);

const attachEventsForAll = (
  currentDom: Ui5DomRef,
  clickHandler: (evt: React.MouseEvent) => void,
) => {
  const treeRoot = getTreeRoot(currentDom);
  const firstLevelItems = treeRoot?.shadowRoot?.querySelectorAll(`${ListNodeTag} > ${LiTreeTag}`);
  if (firstLevelItems?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < firstLevelItems.length; i++) {
      const item = firstLevelItems[i] as UI5Element;
      if (item) {
        const { nodeName } = item;
        if (nodeName === LiTreeTag.toUpperCase()) {
          // eslint-disable-next-line no-underscore-dangle
          EventMap[item.__id as any] = {
            item,
            clickHandler,
          };
          (item as any).onclick = clickHandler;
        }
      }
    }
  }
};

const detachEventsForAll = () => {
  Object.keys(EventMap).forEach((key: string) => {
    const entry = EventMap[key];
    if (entry.item) {
      entry.item.onclick = null;
    }
  });
  EventMap = {};
};

const clickHandler = (evt: React.MouseEvent) => {
  const target: any = evt.target as UI5Element;
  if (
    target?.shadowRoot &&
    target?.icon &&
    target?.level === 1 &&
    !!target?.shadowRoot?.querySelector(`${IconTag}.ui5-li-tree-toggle-icon`)
  ) {
    const foldIcon = target.shadowRoot.querySelector(`${IconTag}.ui5-li-tree-toggle-icon`);
    if (foldIcon) {
      foldIcon.fireEvent('click');
    }
  }
};

const SideNavigationObserver: React.FC<Props> = React.forwardRef((props, ref) => {
  const item = useRef<Ui5DomRef>(null);
  const ui5TreeCallback = () => {
    detachEventsForAll();
    setTimeout(() => {
      if (item.current) {
        attachEventsForAll(item.current, clickHandler);
      }
    }, 1);
  };
  useEffect(() => {
    const currentDom = item.current;
    let observer;
    const timer = setTimeout(() => {
      if (currentDom?.shadowRoot) {
        // add navRoot svg adapter for OpenAPI
        const navRoot = currentDom?.shadowRoot
          .querySelector(`ui5-tree-devx-shell`)
          ?.querySelectorAll(`ui5-tree-item-devx-shell`);
        navRoot?.forEach((nav) => {
          let iconDOM = nav?.shadowRoot.querySelector('.ui5-li-icon');
          console.log('iconDOM:', iconDOM);
          if (iconDOM?.getAttribute('name') == 'accept') {
            if (iconDOM?.shadowRoot?.querySelector('svg')) {
              iconDOM?.shadowRoot?.querySelector('svg').style?.setProperty('display', 'none');
              iconDOM?.shadowRoot?.querySelector('svg')?.insertAdjacentHTML(
                'afterend',
                `
                  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.83179 9.14113C8.83179 10.1174 8.02093 10.9264 6.99884 10.9264C5.97675 10.9264 5.16589 10.1174 5.16589 9.14113C5.16589 8.16482 5.97675 7.35583 6.99884 7.35583C8.02093 7.35583 8.83179 8.16482 8.83179 9.14113Z" stroke="#1D2D3E"/>
                    <path d="M13.4977 2.28529C13.4977 3.26161 12.6868 4.07059 11.6647 4.07059C10.6426 4.07059 9.83179 3.26161 9.83179 2.28529C9.83179 1.30898 10.6426 0.5 11.6647 0.5C12.6868 0.5 13.4977 1.30898 13.4977 2.28529Z" stroke="#1D2D3E"/>
                    <line y1="-0.5" x2="5.18402" y2="-0.5" transform="matrix(0.632542 -0.774526 0.787104 0.616821 8.16528 8.13965)" stroke="#1D2D3E"/>
                    <path d="M1.19141 7.16016L1.27617 7.21075L2.7478 8.43372" stroke="#1D2D3E"/>
                    <path d="M2.52075 5.45129L3.51599 6.25232" stroke="#1D2D3E"/>
                    <path d="M10.5352 11.9961L11.5328 12.8054" stroke="#1D2D3E"/>
                    <path d="M8.48828 13.1094L10.0323 14.3531" stroke="#1D2D3E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.14428 3.535L7.36056 4.58433L7.36367 4.58456L7.03315 5.04696C6.71605 5.49057 6.21747 5.75543 5.70905 5.95252C5.38243 6.07912 5.07991 6.25249 4.81009 6.46419C4.32206 6.84709 3.60826 6.97693 3.12811 6.5842C3.77486 5.64724 4.76707 4.95785 5.92566 4.69143L7.59294 2.33252L8.14428 3.535ZM10.2542 12.4129C11.1227 11.5829 11.662 10.4237 11.662 9.14117C11.662 8.95547 11.6507 8.77235 11.6287 8.59244L12.8199 6.68352L11.9778 6.14985L10.8975 7.86959L10.804 8.00046C10.7513 8.0742 10.7067 8.15208 10.6692 8.2331L10.6467 8.26891L10.6518 8.27208C10.536 8.54159 10.4955 8.84321 10.4955 9.14117C10.4955 9.73058 10.3437 10.2853 10.0762 10.7697C9.78052 11.3051 9.78073 12.0257 10.2542 12.4129ZM9.29676 13.1184L8.77301 12.69C8.49138 12.4597 8.10889 12.4118 7.75324 12.4887C7.50943 12.5413 7.25607 12.5691 6.99609 12.5691C5.06342 12.5691 3.49667 11.0344 3.49667 9.14117C3.49667 8.70242 3.35776 8.26654 3.01353 7.9945L2.56018 7.63622C2.39172 8.10844 2.3302 8.61265 2.3302 9.14117C2.3302 11.6654 4.41919 13.7118 6.99609 13.7118C7.83267 13.7118 8.61782 13.4961 9.29676 13.1184Z" fill="#1D2D3E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274329 7.23972C0.288766 7.19079 0.302891 7.14278 0.31691 7.09553C0.466601 6.59099 1.05153 6.43351 1.48115 6.73747V6.73747L1.64655 6.87249C1.33755 7.56936 1.16647 8.33857 1.16647 9.14677C1.16647 12.3021 3.77771 14.86 6.99884 14.86C8.20112 14.86 9.31843 14.5037 10.2467 13.8929L10.4127 14.0284V14.0284C10.7976 14.3426 10.7805 14.9374 10.3421 15.1714C10.2708 15.2094 10.1988 15.2462 10.1261 15.2819C9.18495 15.7431 8.12284 16.0027 6.99884 16.0027C3.13349 16.0027 0 12.9332 0 9.14677C0 8.485 0.0957169 7.84513 0.274329 7.23972ZM12.6727 13.1617C12.3534 13.5939 11.739 13.6236 11.3227 13.2838L11.1594 13.1506C11.3834 12.9272 11.5889 12.6859 11.7732 12.4291C12.4072 11.5458 12.7922 10.4787 12.8284 9.32562C12.8303 9.26623 12.8312 9.20661 12.8312 9.14677C12.8312 9.08726 12.8303 9.02796 12.8284 8.96889C12.7963 7.94063 12.4869 6.98074 11.9708 6.1586V6.1586C12.3247 5.66355 13.0597 5.66282 13.3251 6.21043C13.3295 6.21953 13.3339 6.22864 13.3382 6.23776C13.7613 7.12084 13.9977 8.10677 13.9977 9.14677C13.9977 10.5734 13.5528 11.8983 12.7916 12.9955C12.7528 13.0515 12.7131 13.1069 12.6727 13.1617V13.1617ZM1.93458 4.41459C1.56943 4.78945 1.64816 5.38641 2.05356 5.71734L2.22791 5.85967C2.41023 5.60622 2.61306 5.36794 2.83404 5.14717C3.84828 4.13387 5.24483 3.48912 6.795 3.43696C6.86266 3.43469 6.93061 3.43354 6.99884 3.43354C7.03259 3.43354 7.06627 3.43382 7.09987 3.43438C7.45664 3.44031 7.80561 3.47763 8.14402 3.54363V3.54363C8.49253 3.05609 8.24383 2.37359 7.64695 2.3199C7.63058 2.31843 7.6142 2.31701 7.5978 2.31565C7.40037 2.29926 7.2006 2.29089 6.99884 2.29089C5.08655 2.29089 3.35339 3.04216 2.09016 4.25983C2.03748 4.31061 2.03718 4.30926 1.93458 4.41459V4.41459Z" fill="#1D2D3E"/>
                  </svg>
                  `,
              );
            }
            return;
          }
        });

        const treeRoot = currentDom.shadowRoot.querySelector(`.ui5-sn-root ${TreeNodeTag}`);
        if (treeRoot) {
          const config = { attributes: true, childList: true, subtree: true };
          observer = new MutationObserver(ui5TreeCallback);
          observer.observe(treeRoot, config);
          attachEventsForAll(currentDom, clickHandler);
          // clear background
          const treeItems = treeRoot.querySelectorAll('ui5-tree-item-rgp-shell');
          treeItems.forEach((item) => {
            if (item?.shadowRoot?.querySelector('li')?.style) {
              const li = item.shadowRoot.querySelector('li') as any;
              if (li.style.background) {
                li.style.background = 'none';
              }
            }
          });
          const allItems = treeRoot.querySelectorAll('ui5-tree-item-rgp-shell[selected]');
          const isMorning = getThemeId() === 'sap_horizon';
          allItems.forEach((item) => {
            if (item?.shadowRoot?.querySelector('li')?.style && isMorning) {
              const li = item.shadowRoot.querySelector('li') as any;
              li.style.background = '#D1EFFF';
            }
          });
        }
      }
    }, 0);
    return () => {
      detachEventsForAll();
      observer?.disconnect();
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    const currentDom = item.current;
    if (currentDom?.shadowRoot) {
      const snRoot = currentDom.shadowRoot.querySelector('.ui5-sn-root') as HTMLDivElement;
      if (snRoot) {
        snRoot.style.padding = '0 0.75rem';
      }
    }
  }, [item]);

  return <SideNavigation ref={item} {...props} />;
});

export default SideNavigationObserver;
