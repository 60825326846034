// @ts-ignore
__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';
// import './public-path';

import React from 'react';
import ReactDOM from 'react-dom/client';
// import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import {
  setCustomElementsScopingSuffix,
  setCustomElementsScopingRules,
} from '@ui5/webcomponents-base/dist/CustomElementsScope';
import { setTheme as setUi5Theme } from '@ui5/webcomponents-base/dist/config/Theme.js';
// import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import '@ui5/webcomponents/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets.js';
import { Provider } from 'react-redux';
import { store } from './common/redux';

import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import { THEME_LIST } from './common/Utils';
import { FetchFunctions } from './types';
import './styles/index.less';

// const { setLanguage } = eureka.I18nProvider;
// const { eventBus } = eurekaMgrs;
const { getThemeId, setThemeId, getDefaultThemeId, setDefaultThemeId, setThemeList } =
  eurekaMgrs.AppearanceManager;

const DEFAULT_THEME = 'sap_horizon';
const themeId = new URLSearchParams(window.location.search).get('sap-ui-theme');

// enable fiori next horizon theme
setDefaultThemeId(DEFAULT_THEME);
setThemeList(THEME_LIST);
setThemeId(themeId || getDefaultThemeId());
setUi5Theme(getThemeId());
if (process.env.NODE_ENV !== 'test' && process.env.APP_NAME) {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
  setCustomElementsScopingRules({
    include: [/^ui5-/],
    exclude: [/^ui5-object-page-anchor-tab/],
  });
}

// if (!isDxp) {
//   eventBus.on('i18n-update', (langCode) => {
//     setLanguage(langCode);
//     setUi5Language(eureka.I18nProvider.getUi5Language(langCode));
//   });
//   eventBus.on('appearance-update', (settings) => {
//     console.log(`Shell UI: appearance settings is ${JSON.stringify(settings)}`);
//     const { themeChanged, themeId } = settings;
//     if (themeChanged) {
//       setThemeId(themeId);
//       setUi5Theme(getThemeId());
//     }
//   });
// }

const fetchFunctions = axiosWrapper() as FetchFunctions;
const container = document.getElementById('devx-app');
if (!container) {
  throw new Error('Cannot find root container for Shell.');
}
ReactDOM.createRoot(container).render(
  <Provider store={store}>
    <App {...fetchFunctions} />
  </Provider>,
);
