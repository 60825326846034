import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import eureka from 'eureka';

import { RootState } from 'src/common/redux';
import { ShellDialog } from 'src/common/plugins/dialog.plugin';
import MessageToast from 'src/common/plugins/toast.plugin';
import { MicroFrontendProps } from 'src/types';
import Tiles from '../components/Tiles';
import Header from 'src/features/components/Header';
import SidePanel from 'src/features/components/SidePanel';
import { getConfig } from 'src/features/components/Header/UserProfile/eureka';

const { Spinner } = eureka.components;

const navigatePage = (props, mfe, router) => {
  if (
    props.config.components &&
    (props.config.components || []).filter((c) => c.name === mfe).length > 0
  ) {
    props.history.push(router);
  }
};

type Props = MicroFrontendProps;

const WelcomePage: React.FC<Props> = (props) => {
  const isMenuShow = useSelector((state: RootState) => state.common.isMenuShow);
  const isDxp = useSelector((state: RootState) => state.common.isDxp);

  useEffect(() => {
    if (!isDxp) {
      navigatePage(
        props,
        'devx-deployment-management-ui',
        `devx/${window.localStorage.getItem('project')}/deployments`,
      );
    } else {
      console.log('project: ', getConfig('project'));
    }
  }, []);

  return (
    <div className="fd-shell fd-shell--fundamentals">
      {!isDxp && (
        <div className="fd-shell__header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={props.history}
              config={props.config}
              settings={props.settings}
              user={props.user}
            />
          </Suspense>
        </div>
      )}
      <div className="fd-shell__app">
        <div className="fd-app">
          {!isDxp && (
            <div
              className={`fd-app__navigation fd-app__navigation--vertical${
                isMenuShow ? ' show-menu' : ' hide-menu'
              }`}
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel history={props.history} match={props.match} config={props.config} />
              </Suspense>
            </div>
          )}
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              <div className="home-page">
                <Tiles history={props.history} config={props.config} />
              </div>
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="welcome-page-dialog" />
      <MessageToast key="welcome-page-toast" />
    </div>
  );
};

export default WelcomePage;
